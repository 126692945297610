import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { Route, Switch } from "react-router";
import suspenseHoc from "./hoc/suspenseHoc";
import merchantHeaderHoc from "./hoc/merchantHeaderHoc";
import { darkTheme, lightTheme } from "./utils/theme";
import LoginPageHoc from "./hoc/LoginPageHoc";
import employeeHeaderHoc from "./hoc/employeeHeaderHoc";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmployeeAuthHoc from "./hoc/EmployeeAuthHoc";
import MerchantAuthHoc from "./hoc/MerchantAuthHoc";
import LoadingModal from "./components/LoadingModal";
import DistributorAuthHoc from "./hoc/DistributorAuthHoc";
import distributorHeaderHoc from "./hoc/distributorHeaderHoc";
import userHeaderHoc from "./hoc/userHeaderHoc";

import UserAuthHoc from "./hoc/UserAuthHoc";
import UserRoutes from "./routes/UserRoutes";

const LandingPage = React.lazy(() => import("./pages/LandingPage"));
const MerchantLoginPage = React.lazy(() => import("./pages/MerchantLoginPage"));
const DistributorLoginPage = React.lazy(() =>
  import("./pages/DistributorLoginPage")
);
const UserLoginPage = React.lazy(() => import("./pages/UserLoginPage"));
const MerchantAppLoginPage = React.lazy(() =>
  import("./pages/MerchantAppLoginPage")
);
const EmployeeLoginPage = React.lazy(() => import("./pages/EmployeeLoginPage"));
const EmployeeOnboardingPage = React.lazy(() =>
  import("./pages/EmployeeOnboardingPage")
);
const PartnerOnboardingPage = React.lazy(() =>
  import("./pages/PartnerOnboardingPage")
);
const PartnerOnboardingPaymentPage = React.lazy(() =>
  import("./pages/PartnerOnboardingPaymentPage")
);
const PartnerPettyCashPaymentForm = React.lazy(() =>
  import("./pages/PartnerPettyCashPaymentForm")
);
const MerchantRoutes = React.lazy(() => import("./routes/MerchantRoutes"));
const DistributorRoutes = React.lazy(() =>
  import("./routes/DistributorRoutes")
);
const EmployeeRoutes = React.lazy(() => import("./routes/EmployeeRoutes"));

function App() {
  React.useEffect(() => {
    window.onload = function () {
      const appVersion = window.localStorage.getItem("appVersion");
      if (!appVersion) {
        window.localStorage.setItem("appVersion", "19.07.24m");
      } else if (appVersion !== "19.07.24m") {
        toast.info("Please wait, updating new version...");
        setTimeout(() => {
          window.localStorage.clear();
          window.localStorage.setItem("appVersion", "19.07.24m");
          window.location.reload();
        }, 3000);
      }
    };
  }, []);
  const theme = window.localStorage.theme ? window.localStorage.theme : "light";
  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <Switch>
        <Route exact path="/" component={suspenseHoc(LandingPage)} />
        <LoginPageHoc
          exact
          path="/merchant-login"
          component={suspenseHoc(MerchantLoginPage)}
        />
        <LoginPageHoc
          exact
          path="/distributor-login"
          component={suspenseHoc(DistributorLoginPage)}
        />
        <LoginPageHoc
          exact
          path="/user-login"
          component={suspenseHoc(UserLoginPage)}
        />
        <LoginPageHoc
          exact
          path="/partner-app-login"
          component={suspenseHoc(MerchantAppLoginPage)}
        />
        <LoginPageHoc
          exact
          path="/employee-login"
          component={suspenseHoc(EmployeeLoginPage)}
        />
        <MerchantAuthHoc
          path="/merchant"
          component={suspenseHoc(merchantHeaderHoc(MerchantRoutes))}
        />
        <DistributorAuthHoc
          path="/distributor"
          component={suspenseHoc(distributorHeaderHoc(DistributorRoutes))}
        />
        <UserAuthHoc
          path="/user"
          component={suspenseHoc(userHeaderHoc(UserRoutes))}
        />
        <EmployeeAuthHoc
          path="/employee"
          component={suspenseHoc(employeeHeaderHoc(EmployeeRoutes))}
        />
        <Route
          exact
          path="/employee-onboarding"
          component={suspenseHoc(EmployeeOnboardingPage)}
        />
        <Route
          exact
          path="/partner-onboarding"
          component={suspenseHoc(PartnerOnboardingPage)}
        />
        <Route
          exact
          path="/partner-onboarding-payment"
          component={suspenseHoc(PartnerOnboardingPaymentPage)}
        />
        <Route
          exact
          path="/pettycash/form"
          component={suspenseHoc(PartnerPettyCashPaymentForm)}
        />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable
        pauseOnHover={false}
      />
      <LoadingModal />
    </ThemeProvider>
  );
}

export default App;
