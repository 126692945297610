import * as actionTypes from "../actionTypes";

let initialState = {
  approvalList: [],
  clientList: [],
};

export const employeeOperationsHeadReducers = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.SET_EMPLOYEE_OPERATIONS_HEAD_APPROVAL_LIST:
      return {
        ...state,
        approvalList: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_LOGOUT:
      return {
        approvalList: [],
      };
    case actionTypes.FETCH_ALL_CLIENTS:
      return {
        clientList: action.payload,
      };
    default:
      return state;
  }
};
