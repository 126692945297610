import moment from "moment";

export const API_URL = process.env.REACT_APP_API_URL;
export const RZP_KEY = process.env.REACT_APP_RZP_KEY;

/**
 * Whatsapp access
 */

export const WHATSAPP_ACCESS = ["admin", "rangnit", "parth", "pratik"];

/**
 * Employee
 */
export const DEFAULT_EMPLOYEE_ROLES = [
  "sales",
  "sales head",
  "area manager",
  "operations head",
  "aggregator",
  "accounts",
  "marketing",
  "inventory",
  "tech",
  "crm",
  "purchase",
  "Food quality/Customer feedback support",
  "Maintenance",
  "HP Warehouse",
  "Mumbai GK CK",
  "store manager",
];

export const DEFAULT_EMPLOYEE_ROLES_PARTNER_TICKET = [
  "area manager",
  "aggregator",
  "accounts",
  "inventory",
  "marketing",
  "crm",
  "purchase",
  "Food quality/Customer feedback support",
  "Maintenance",
];

/**
 * Store
 */
export const DEFAULT_COMPANY_TYPE = [
  "Sole proprietorship",
  "One person company",
  "Pvt Ltd",
  "LLP",
  "Individual",
  "Partnership",
];

export const STORE_STATUS = [
  "initiated",
  "payment-completed",
  "area-manager-approved",
  "operation-head-approved",
  "owner-filled",
  "created",
  "rejected",
];

export const DEFAULT_KYC_DOCUMENT_TYPE = [
  "PAN Card",
  "Aadhar Card",
  "Driving license",
  "Passport",
  "Others",
];

export const getDateFormat = (from, to) => {
  let temp = "";
  if (moment(from).isSame(moment(to), "date")) {
    if (moment().isSame(moment(from), "date")) {
      temp = `Today ${moment(to).format("MMM DD, YYYY")}`;
    } else if (moment().subtract(1, "day").isSame(moment(from), "date")) {
      temp = `Yesterday ${moment(to).format("MMM DD, YYYY")}`;
    } else {
      temp = `${moment(from).format("MMM DD, YYYY")}`;
    }
  } else if (
    moment(from).isSame(moment().subtract(7, "days"), "date") &&
    moment(to).isSame(moment(), "date")
  ) {
    temp = `Last week ${moment(from).format("MMM DD")} - ${moment(to).format(
      "MMM DD, YYYY"
    )}`;
  } else {
    temp = `${moment(from).format("MMM DD")} - ${moment(to).format(
      "MMM DD, YYYY"
    )}`;
  }
  return temp;
};

export const DEFAULT_ONE_HOUR_INTERVAL = [
  {
    value: "00:00",
    name: "12:00 AM - 01:00 AM",
  },
  {
    value: "01:00",
    name: "01:00 AM - 02:00 AM",
  },
  {
    value: "02:00",
    name: "02:00 AM - 03:00 AM",
  },
  {
    value: "03:00",
    name: "03:00 AM - 04:00 AM",
  },
  {
    value: "04:00",
    name: "04:00 AM - 05:00 AM",
  },
  {
    value: "05:00",
    name: "05:00 AM - 06:00 AM",
  },
  {
    value: "06:00",
    name: "06:00 AM - 07:00 AM",
  },
  {
    value: "07:00",
    name: "07:00 AM - 08:00 AM",
  },
  {
    value: "08:00",
    name: "08:00 AM - 09:00 AM",
  },
  {
    value: "09:00",
    name: "09:00 AM - 10:00 AM",
  },
  {
    value: "10:00",
    name: "10:00 AM - 11:00 AM",
  },
  {
    value: "11:00",
    name: "11:00 AM - 12:00 PM",
  },
  {
    value: "12:00",
    name: "12:00 PM - 01:00 PM",
  },
  {
    value: "13:00",
    name: "01:00 PM - 02:00 PM",
  },
  {
    value: "14:00",
    name: "02:00 PM - 03:00 PM",
  },
  {
    value: "15:00",
    name: "03:00 PM - 04:00 PM",
  },
  {
    value: "16:00",
    name: "04:00 PM - 05:00 PM",
  },
  {
    value: "17:00",
    name: "05:00 PM - 06:00 PM",
  },
  {
    value: "18:00",
    name: "06:00 PM - 07:00 PM",
  },
  {
    value: "19:00",
    name: "07:00 PM - 08:00 PM",
  },
  {
    value: "20:00",
    name: "08:00 PM - 09:00 PM",
  },
  {
    value: "21:00",
    name: "09:00 PM - 10:00 PM",
  },
  {
    value: "22:00",
    name: "10:00 PM - 11:00 PM",
  },
  {
    value: "23:00",
    name: "11:00 PM - 12:00 PM",
  },
];
