import { toast } from "react-toastify";
import { setEmployeeLoading } from ".";
import { employee_http } from "../../https/employee_http";
import { API_URL } from "../../utils/constants";
import * as actionTypes from "../actionTypes";

export const fetchEmployeeApprovalList = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/gk_emp?permanent=false&limit=100&page=1`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_APPROVAL_LIST,
          payload: res.data.result,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchEmployeeList = (status, name) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/gk_emp?permanent=true&limit=1000&page=1`;
    if (status === "terminated") {
      url += "&terminated=true";
    }
    if (name) {
      url += `&name=${name}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_LIST,
          payload: res.data.result,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadAllEmployees = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/gk_emp/download`, {
        responseType: "blob",
      })
      .then(async (response) => {
        const filename = "employees_data.xlsx";

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(response.data);
        downloadLink.setAttribute("download", filename);

        downloadLink.click();

        await dispatch(setEmployeeLoading(false));
      })
      .catch((error) => {
        console.error("Error during file download:", error);
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateEmployeeCred = (data, id, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/gk_emp/creds/${id}`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_EMPLOYEE_LIST,
          payload: res.data,
        });
        cb();
        toast.success("Updated successfully");
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Error while updating");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const approveOrRejectEmployeeOnboarding = (data, id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/gk_emp/${id}`, data)
      .then(async () => {
        await dispatch(setEmployeeLoading(false));
        await dispatch(fetchEmployeeApprovalList());
        toast.success(
          data.approved ? "Application accepted" : "Application rejected"
        );
      })
      .catch(() => {
        toast.error(
          data.approved ? "Error while approving" : "Error while rejecting"
        );
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchEmployeeDepartmentList = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/department`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_DEPARTMENT_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const createEmployeeDepartmentList = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/department`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.ADD_EMPLOYEE_DEPARTMENT_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateEmployeeDepartment = (id, name, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/department/${id}`, { name })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_EMPLOYEE_DEPARTMENT_LIST,
          payload: res.data,
        });
        cb();
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadEmployeeExcel = (file) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("file", file);
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/gk_emp/upload/bulk`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success(`
          Added: ${res.data?.added_count},
          Error: ${res.data?.error_count}
        `);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong, please check employee list");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchEmployeeRegionList = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/region`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_REGION_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateEmployeeRegion = (id, name, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/region/${id}`, { name })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_EMPLOYEE_REGION_LIST,
          payload: res.data,
        });
        cb();
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const createEmployeeRegionList = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/region`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.ADD_EMPLOYEE_REGION_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const sendEmployeeOnboardInvite = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/gk_emp/send-email`, data)
      .then(() => {
        toast.success("Email sent !");
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Failed to send, try again !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateEmployeeDetails = (id, data, cb) => {
  console.log("update called");
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/gk_emp/${id}`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_EMPLOYEE_LIST,
          payload: res.data,
        });
        cb();
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateEmployeeDocument = (id, { docType, file }, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("docType", docType);
    formData.append("file", file);
    employee_http
      .put(`${API_URL}/gk_emp/${id}/upload/document`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_EMPLOYEE_LIST,
          payload: res.data,
        });
        if (cb) {
          cb();
        }
        await dispatch(setEmployeeLoading(false));
        toast.success("Document Updated !");
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
        toast.error("Unable to update the document !");
      });
  };
};

export const terminateEmployee = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/gk_emp/terminate/${id}`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.DELETE_EMPLOYEE_LIST,
          payload: id,
        });
        cb();
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const appraisalEmployee = (
  open,
  setDate,
  salary,
  designation,
  docFile,
  handleSuccess
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", docFile);
    let data = {
      salary: salary,
      designation: designation,
      date: setDate,
    };
    formData.append("salary", salary);
    formData.append("date", setDate);
    formData.append("designation", designation);

    employee_http
      .put(`${API_URL}/gk_emp/appraisal/${open}`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_EMPLOYEE_LIST,
          // payload: id,
        });
        // cb();
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const checkBioMetricCode = (code) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/gk_emp/biometriccode`)
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.UPDATE_EMPLOYEE_LIST,
        //   payload: res.data,
        // });
        // toast.success("Updated successfully");
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        // toast.error("Error while updating");
        dispatch(setEmployeeLoading(false));
      });
  };
};
