import axios from "axios";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { setEmployeeLoading, setMerchantLoading, setReloadAPI } from ".";
import { employee_http } from "../../https/employee_http";
import { merchant_http } from "../../https/merchant_http";
import { API_URL } from "../../utils/constants";
import * as actionTypes from "../actionTypes";

export const fetchStoreApprovalList = (status) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/store?status=${status}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_APPROVAL_LIST,
          payload: res.data,
        });
        await dispatch({
          type: actionTypes.SET_STORE_LIST,
          payload: res.data,
        });

        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchMerchantStoreApprovalList = (status) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
      .get(`${API_URL}/store?status=${status}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_APPROVAL_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const deleteStore = (storeId) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .delete(`${API_URL}/store/${storeId}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.DELETE_APPROVAL_LIST,
          payload: storeId,
        });
        await dispatch(fetchStoreStatusCount());
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const fetchNonRejectedStoreList = (sort, region_id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/store?collection=not_rejected`;
    if (sort) url += `&sort=desc`;
    if (region_id) url += `&region_id=${region_id}`;
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ALL_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchPartnerAMList = (status) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/store/area-manager?status=${status}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_AREA_MANAGER_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const approveStoreOnboarding = (data, id, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/creds/${id}`, data)
      .then(async () => {
        cb();
        await dispatch(setEmployeeLoading(false));
        await dispatch(setReloadAPI(true));
        toast.success("Username generated");
      })
      .catch(() => {
        toast.error("Error while generating user name");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const addRestaurantDetails = (data, id, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/rista/${id}`, data)
      .then(async () => {
        cb();
        await dispatch(setEmployeeLoading(false));
        await dispatch(setReloadAPI(true));
        toast.success("Details Saved");
      })
      .catch(() => {
        toast.error("Error while generating user name");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const initiatePartnerOnBoarding = (data, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/initiate`, data)
      .then((res) => {
        cb(res.data);
        toast.success("Initiated successfully");
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("partner onboarding failed");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getStoreDetails = (id, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    axios
      .get(`${API_URL}/store/${id}`)
      .then((res) => {
        if (cb) cb(res.data);
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const getAllStore = () => {
  console.log("Hitting!!");
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/store/allstore`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.GET_ALL_STORE,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const createPaymentOrder = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    axios
      .post(`${API_URL}/store/${id}/payment/order`, data)
      .then((res) => {
        if (cb) cb(res.data);
        dispatch(setEmployeeLoading(false));
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Error while generating payment order. Please try again or contact administrator."
        );
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const ownerUpdateStoreDetails = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    axios
      .put(`${API_URL}/store/${id}/owner/update`, data)
      .then((res) => {
        if (cb) cb(res.data);
        toast.success("Application submitted successfully!");
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error(
          "Error while submitting, fill all mandatory fields and upload file size less than 2 MB"
        );
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateStoreDetails = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/store/${id}/update`, data)
      .then(async (res) => {
        if (res.data.status === "created") {
          await dispatch({
            type: actionTypes.SET_STORE_LIST,
            payload: res.data,
          });
        } else {
          await dispatch({
            type: actionTypes.SET_STORE_LIST,
            payload: res.data,
          });
        }
        if (cb) {
          cb();
        }
        await dispatch(setEmployeeLoading(false));
        toast.success("Updated !");
      })
      .catch(() => {
        toast.error("Unable to update Store");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateStoreBrandDetails = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/store/${id}/update/brand`, data)
      .then(async (res) => {
        if (res.data.status === "created") {
          await dispatch({
            type: actionTypes.UPDATE_STORE_LIST,
            payload: res.data,
          });
        } else {
          await dispatch({
            type: actionTypes.UPDATE_STORE_APPROVAL_LIST,
            payload: res.data,
          });
        }
        if (cb) {
          cb();
        }
        await dispatch(setEmployeeLoading(false));
        toast.success("Updated !");
      })
      .catch(() => {
        toast.error("Unable to update Store brands");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchStoreList = (nickname, sort) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/store?status=created`;
    if (nickname) url += `&nickname=${nickname}`;
    if (sort) url += `&sort=desc`;
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchStoreListMerchant = (nickname, sort) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/store?status=created`;
    if (nickname) url += `&nickname=${nickname}`;
    if (sort) url += `&sort=desc`;
    merchant_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const toggleCredit = (id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/changeCredit`, id)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchRistaIntegrationStoreList = (nickname, sort) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/store?status=rista-integration`;
    if (nickname) url += `&nickname=${nickname}`;
    if (sort) url += `&sort=desc`;
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_RISTA_INTEGRATION_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchMappings = async () => {
  let url = `${API_URL}/brand/store-mappings`;
  const response = await merchant_http.get(url);

  return response.data;
};
export const fetchAllCPCRequests = () => {
  return (dispatch) => {
    console.log("okkk");
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/brand/getAllCPCs`;
    employee_http
      .get(url)
      .then(async (res) => {
        console.log("debug--", res.data);
        await dispatch({
          type: actionTypes.GET_ALL_CPC_REQUESTS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
    // const response = await employee_http.get(url);
    // console.log(response.data);
  };
};
export const updateCPCStatus = (id, status) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/brand/changeCPCStatus`;
    employee_http
      .post(url, { id, status })
      .then(async (res) => {
        console.log(res.data);
        await dispatch({
          type: actionTypes.GET_ALL_CPC_REQUESTS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
    // const response = await employee_http.get(url);
    // console.log(response.data);
  };
};
export const fetchChannelList = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/brand/channels/all`;
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_CHANNEL_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchCPCRequests = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/brand/getAllCPCs`;
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.GET_ALL_CPC,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updatePartnerTimingDetails = (id, data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/store/timing/${id}`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updatePartnerAddressDetails = (id, data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/store/address/${id}`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchFssaiDetails = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/store/fssai`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_FSSAI_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadAllPartners = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/store/download`, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `partner_${new Date()}.xls`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchStoreStatusCount = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/store/count`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_STATUS_COUNT,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchPartnerPaymentLinks = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/partner-payments/link?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARTNER_PAYMENT_LINK_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const createNewPartnerPaymentLink = (data, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/partner-payments/link`, data)
      .then(() => {
        cb();
        dispatch(setEmployeeLoading(false));
        toast.success("Created new payment link!");
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const sendAggregatorOnboardingEmail = (aggregator, storeID) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/aggregate/${storeID}`, {
        aggregators: [aggregator],
      })
      .then(async (res) => {
        toast.success(`Mail sent to ${aggregator} team`);
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const sendRistaInitiationEmail = (storeID) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/aggregate/${storeID}/rista_initiate`, {})
      .then(async (res) => {
        toast.success(`Mail sent to rista team`);
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const sendRistaTrainingEmail = (data, storeID) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/aggregate/${storeID}/rista_training`, data)
      .then(async (res) => {
        toast.success(`Mail sent to rista training team`);
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateStoreDocument = (storeID, { name, value, file }, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("name", name);
    formData.append("value", value);
    if (file) formData.append("file", file);
    employee_http
      .put(`${API_URL}/store/${storeID}/upload/document`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        await dispatch({
          type: actionTypes.UPDATE_STORE_APPROVAL_LIST,
          payload: res.data,
        });
        if (cb) {
          cb();
        }
        await dispatch(setEmployeeLoading(false));
        toast.success("Document Updated !");
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
        toast.error("Unable to update the document !");
      });
  };
};

export const uploadStoreInvoice = (storeID, file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http
      .post(`${API_URL}/store/${storeID}/upload/invoice`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
        toast.success("Invoice Uploaded !");
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
        toast.error("Error while uploading the invoice !");
      });
  };
};

export const createStoreFromAccountsApproval = (storeID) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/${storeID}/create`)
      .then(async () => {
        await dispatch(setEmployeeLoading(false));
        await dispatch(setReloadAPI(true));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateStoreLaunchDetails = (storeID, form, success) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/store/${storeID}/launch/update`, form)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        if (success) {
          success();
        }
        await dispatch(setEmployeeLoading(false));
        toast.success("Update Success!");
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
        toast.error("Update Failed!");
      });
  };
};

export const uploadFssaiReceipt = (file, storeID) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http
      .put(`${API_URL}/store/${storeID}/upload/fssai-receipt`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadExternalPaymentReceipt = (
  file,
  payment_mode,
  amount,
  storeID,
  cb
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    formData.append("data", JSON.stringify({ payment_mode, amount }));
    employee_http
      .put(
        `${API_URL}/store/${storeID}/upload/external-payment-receipt`,
        formData,
        {
          "Content-Type": "multipart/form-data",
        }
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        await cb();
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadFssaiCertificate = (file, fssai_expiry, storeID) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    formData.append("data", JSON.stringify({ fssai_expiry }));
    employee_http
      .put(`${API_URL}/store/${storeID}/upload/fssai-certificate`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updatePartnerState = (state, storeID) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/store/${storeID}/update/state`, { state })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getStoreBrandMapping = (storeID) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    dispatch({
      type: actionTypes.SET_STORE_BRAND_MAPPING,
      payload: [],
    });
    employee_http
      .get(`${API_URL}/brand/${storeID}/mapping`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_BRAND_MAPPING,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateStoreBrandMapping = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/brand/update/mapping`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_BRAND_MAPPING,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadRevenueShareConfig = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/brand/mapping/revenue_share/download`, {
        responseType: "blob",
      })
      .then(async (res) => {
        fileDownload(res.data, `revenue-share-config.xlsx`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to download!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadRevenueShareConfig = (file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http
      .put(`${API_URL}/brand/mapping/revenue_share/upload`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success(res.data.message);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Error while uploading file!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getStoreBrandZSUrls = (storeID) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    dispatch({
      type: actionTypes.SET_STORE_BRAND_ZS_URL,
      payload: [],
    });
    employee_http
      .get(`${API_URL}/brand/${storeID}/zsmapping`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_BRAND_ZS_URL,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateStoreBrandZSUrls = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/brand/update/zsmapping`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_BRAND_ZS_URL,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateStoreBrandDiscount = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/brand/update/mapping/discount`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_BRAND_MAPPING,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchEmployeeSalesSummary = (query) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/sale/summary?${query}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_SALES_SUMMARY,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchEmployeeSalesInsights = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/sale/insights?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_SALES_INSIGHTS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchEmployeePayoutSchedules = (from, to) => {
  return (dispatch) => {
    // dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/payout-schedules`;
    if (from && to) {
      url += `?from=${from}&to=${to}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_PAYOUT_SCHEDULES,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchEmployeeBloggerOrder = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    // let url = `${API_URL}/payout-schedules`;
    // if (from && to) {
    //   url += `?from=${from}&to=${to}`;
    // }
    // url += `?data=${data}`;
    // console.log("debug-- ", url);

    employee_http
      .post(`${API_URL}/payout-schedules/blogger-order`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_BLOGGER_ORDER,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchEmployeeHourlySales = (from, to, region) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/summary-by-week?from=${from}&to=${to}`;
    if (region) {
      url = url + `&region=${region}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_HOURLY_SALES,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchPartnerPayoutSchedules = () => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .get(`${API_URL}/payout-schedules/partner`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARTNER_PAYOUT_SCHEDULES,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const uploadEmployeePayoutSchedules = (id, formData, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/payout-schedules/${id}/upload`, formData)
      .then(async (res) => {
        toast.success(res.data.message);
        await dispatch(setEmployeeLoading(false));
        cb();
      })
      .catch(() => {
        toast.error("Unable to upload !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateSalesFFPAndAggregatorCommission = (
  id,
  source,
  from,
  to,
  cb
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(
        `${API_URL}/sale/update/ffp-aggregator-commission?from=${from}&to=${to}&source=${source}&payout_schedule_id=${id}`
      )
      .then(async (res) => {
        toast.success(res.data.message);
        await dispatch(setEmployeeLoading(false));
        cb();
      })
      .catch(() => {
        toast.error("Unable to update !");
        dispatch(setEmployeeLoading(false));
        cb();
      });
  };
};

export const updatePayoutSchedulesHide = (id, hideFromPartner) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/payout-schedules/${id}/hide-status`, {
        hideFromPartner,
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_EMPLOYEE_PAYOUT_SCHEDULES,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to update status !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadPayoutSchedules = (id, name) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/payout-schedules/${id}/download`, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `${name}.xlsx`);
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadPayoutAccountingSchedules = (id, name) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/payout-schedules/${id}/download/accounting`, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `${name}-accounting.xlsx`);
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const createNewPayoutSchedules = (data, cb) => {
  return (dispatch) => {
    // dispatch(setEmployeeLoading(true));
    toast.success("Payout creation is in progress !");
    employee_http
      .post(`${API_URL}/payout-schedules`, data)
      .then(() => {
        toast.success("Created new schedule !");
        setEmployeeLoading(false);
        cb();
      })
      .catch(() => {
        toast.error("Unable to create new schedules !");
        dispatch(setEmployeeLoading(false));
      });
    setEmployeeLoading(false);
  };
};

export const deletePayoutSchedules = (id, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .delete(`${API_URL}/payout-schedules/${id}`)
      .then(async () => {
        dispatch(setEmployeeLoading(false));
        toast.success("Payout schedule deleted successfully!");
        cb();
      })
      .catch(() => {
        toast.success("unable to delete payout schedule!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const schedulePayoutPayment = (data, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/payout-schedules/payment`, data)
      .then(() => {
        toast.success("Scheduled Payout Payments !");
        dispatch(setEmployeeLoading(false));
        cb();
      })
      .catch(() => {
        toast.error("Unable to schedule payout payments !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const validatePartnerPayoutAccount = (id, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/payout-schedules/validate`, { partnerId: id })
      .then(() => {
        toast.success("Validation Request Initiated!");
        dispatch(setEmployeeLoading(false));
        cb();
      })
      .catch(() => {
        toast.error("Unable to request account validation !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchMerchantSalesInsights = (from, to) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .get(`${API_URL}/sale/insights/partner?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_SALES_INSIGHTS,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const uploadPartnerExcel = (file) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("file", file);
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/upload/bulk`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success(`
          Added: ${res.data?.added_count},
          Error: ${res.data?.error_count}
        `);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong, please check employee list");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchRistaItemsOutOfStock = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/rista/item/out-of-stock/report`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_RISTA_ITEMS_OUT_OF_STOCK,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadPartnersSwiggyZomatoDetails = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/brand/download/zsbrand`, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `swiggy_zomato_details_${new Date()}.xlsx`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadPartnersSwiggyZomatoDetails = (file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http
      .post(`${API_URL}/brand/upload/zsbrand`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success(res.data.message);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateRistaMapping = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/update-id-mappings`, data)
      .then(async (res) => {
        await dispatch(setEmployeeLoading(false));
        toast.success("Updated mapping!");
      })
      .catch(() => {
        toast.error("Unable to update Store");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadGKInvestments = (file, fromDate, toDate, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    formData.append("fromDate", fromDate);
    formData.append("toDate", toDate);
    employee_http
      .post(`${API_URL}/gk_investments/upload`, formData)
      .then(async (res) => {
        await dispatch(setEmployeeLoading(false));
        cb();
        toast.success("Uploaded!");
      })
      .catch(() => {
        toast.error("Unable to upload !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchGkInvestments = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/gk_investments/`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_GK_INVESTMENTS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchPartnerGkInvestments = () => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .get(`${API_URL}/gk_investments/partner`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_GK_INVESTMENTS,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const fetchBloggerOrders = (from, to) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .get(`${API_URL}/sale/blogger?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_BLOGGER_ORDERS,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const fetchAllRegionSalesSummary = (
  from,
  to,
  from2,
  to2,
  locType,
  cityType,
  age
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/summary/region/all?from=${from}&to=${to}&from2=${from2}&to2=${to2}`;
    if (locType) {
      url = url + `&loc=${locType}`;
    }
    if (cityType) {
      url = url + `&city=${cityType}`;
    }
    if (age) {
      url = url + `&age=${age}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ALL_REGIONS_SALES_SUMMARY,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchRegionSalesSummary = (region, from, to, from2, to2) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/analytics/sale/summary/region/${region}?from=${from}&to=${to}&from2=${from2}&to2=${to2}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_REGION_SALES_SUMMARY,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchBranchSalesSummary = (store_id, from, to, from2, to2) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/analytics/sale/summary/store/${store_id}?from=${from}&to=${to}&from2=${from2}&to2=${to2}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_BRANCH_SALES_SUMMARY,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchBrandSalesSummary = (brand_id, from, to, from2, to2) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/analytics/sale/summary/brand/${brand_id}?from=${from}&to=${to}&from2=${from2}&to2=${to2}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_BRAND_SALES_SUMMARY,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchChannelSalesSummary = (channel, from, to, from2, to2) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/analytics/sale/summary/channel/${channel}?from=${from}&to=${to}&from2=${from2}&to2=${to2}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_CHANNEL_SALES_SUMMARY,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchAllBranchSalesSummary = (
  from,
  to,
  from2,
  to2,
  locType,
  cityType,
  age
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/summary/store/all?from=${from}&to=${to}&from2=${from2}&to2=${to2}`;
    if (locType) {
      url = url + `&loc=${locType}`;
    }
    if (cityType) {
      url = url + `&city=${cityType}`;
    }
    if (age) {
      url = url + `&age=${age}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ALL_BRANCHES_SALES_SUMMARY,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchAllBrandSalesSummary = (
  from,
  to,
  from2,
  to2,
  locType,
  cityType,
  age
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/summary/brand/all?from=${from}&to=${to}&from2=${from2}&to2=${to2}`;
    if (locType) {
      url = url + `&loc=${locType}`;
    }
    if (cityType) {
      url = url + `&city=${cityType}`;
    }
    if (age) {
      url = url + `&age=${age}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ALL_BRANDS_SALES_SUMMARY,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchAllChannelSalesSummary = (
  from,
  to,
  from2,
  to2,
  locType,
  cityType,
  age
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/summary/channel/all?from=${from}&to=${to}&from2=${from2}&to2=${to2}`;
    if (locType) {
      url = url + `&loc=${locType}`;
    }
    if (cityType) {
      url = url + `&city=${cityType}`;
    }
    if (age) {
      url = url + `&age=${age}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ALL_CHANNELS_SALES_SUMMARY,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateMerchantGSTDocument = ({ name, value, file }, cb) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    let formData = new FormData();
    formData.append("name", name);
    formData.append("value", value);
    if (file) formData.append("file", file);
    merchant_http
      .put(`${API_URL}/store/merchant/upload/gst`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        if (cb) {
          cb();
        }
        await dispatch(setMerchantLoading(false));
        toast.success("GST Updated !");
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
        toast.error("Unable to update the GST !");
      });
  };
};

export const saveInvoicePayment = (
  { partner_id, amount, modeOfPayment, invoiceFile },
  cb
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("partner_id", partner_id);
    formData.append("amount", amount);
    formData.append("modeOfPayment", modeOfPayment);
    if (invoiceFile) formData.append("file", invoiceFile);
    employee_http
      .post(`${API_URL}/invoice/upload`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        if (cb) {
          cb();
        }
        await dispatch(setEmployeeLoading(false));
        toast.success("Invoice Saved !");
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
        toast.error("Unable to save the Invoice !");
      });
  };
};

export const bulkUploadInvoicePayments = (file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http
      .post(`${API_URL}/invoice/upload/bulk`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch(setEmployeeLoading(false));
        if (res.data?.message) {
          toast.success(res.data?.message);
        } else {
          toast.success("Invoice uploaded !");
        }
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
        toast.error("Unable to save the Invoice !");
      });
  };
};

export const fetchPartnerInvoices = (from, to) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .get(`${API_URL}/invoice/all?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARTNER_INVOICES,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const fetchPartnerPayoutTransactions = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .post(`${API_URL}/payout-schedules/transactions`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARTNER_PAYOUT_TRANSACTIONS,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const fetchAreaManagerOverview = (region, referenceMonth) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/area-manager/overview?region=${region}`;
    if (referenceMonth) {
      url = url + `&referenceMonth=${referenceMonth}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_AREA_MANAGER_OVERVIEW,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchAreaManagerRegions = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/analytics/sale/area-manager/region`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_AREA_MANAGER_REGION,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchEmployeePayoutTransactions = (data, store_id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(
        `${API_URL}/payout-schedules/emp/transactions?store_id=${store_id}`,
        data
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_PAYOUT_TRANSACTIONS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadPODetailsExcel = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/po-details-excel`, data, {
        "Content-Type": "multipart/form-data",
      })
      .then(() => {
        toast.success("Uploaded successfully !");
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Error in uploading excel !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadFSSAITemplate = (id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/store/fssai/template`, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `fssai_${new Date()}.xlsx`);
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadFSSAITemplate = (data, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/fssai/template`, data, {
        "Content-Type": "multipart/form-data",
      })
      .then(() => {
        if (cb) {
          cb();
        }
        toast.success("Uploaded successfully !");
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Error in uploading excel !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const notifyPayoutPartners = (id, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/payout-schedules/${id}/send-mail`)
      .then(async (res) => {
        toast.success(res.data.message);
        await dispatch(setEmployeeLoading(false));
        cb();
      })
      .catch(() => {
        toast.error("Unable to send mail !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const sendAgreement = (store_id, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/send-agreement?store_id=${store_id}`)
      .then(async (res) => {
        if (cb) {
          cb();
        }
        toast.success("Request Raised !");
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to raise request !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const addComment = (id, status, text, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    axios
      .post(`${API_URL}/store/add-comment`, { id, status, text })
      .then((res) => {
        if (cb) cb(res.data);
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const addSoftwareFee = (id, pos, pragati, famepilot, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}//add-softwarefee/add-softwarefee`, {
        id,
        pos,
        pragati,
        famepilot,
      })
      .then((res) => {
        if (cb) cb(res.data);
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const createNewCPC = (formData, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/brand/createCpc`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        dispatch(setEmployeeLoading(false));
        cb();
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const fetchPartnerBrandWisePayout = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .post(`${API_URL}/payout-schedules/store/brand-wise`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_BRAND_WISE_PAYOUT,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const getAllBrands = () => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .get(`${API_URL}/brand/`)
      .then(async (res) => {
        // console.log("DEBUG-- ", res.data);
        await dispatch({
          type: actionTypes.GET_ALL_BRAND_LIST,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};
export const getAllBrandsMerchant = () => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .get(`${API_URL}/brand/`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.GET_ALL_BRAND_LIST,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};
export const addOperationalEmailStore = (body, cb) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .post(`${API_URL}/store/add-operatoionalEmail`, body)
      .then(async (res) => {
        if (cb) {
          cb();
        }
        await dispatch(setMerchantLoading(false));
        toast.success("Successfully Added");
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};
export const addFinancialEmailStore = (body) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .post(`${API_URL}/store/add-financialEmail`, body)
      .then(async (res) => {
        await dispatch(setMerchantLoading(false));
        toast.success("Successfully Added");
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const addOperationalPhoneNumberStore = (body, cb) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .post(`${API_URL}/store/add-operatoionalPhoneNumber`, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_APPROVAL_LIST,
          payload: res.data,
        });
        if (cb) cb();
        await dispatch(setMerchantLoading(false));
        toast.success("Successfully Added");
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};
export const addCuisinePhoneNumberStore = (body, cb) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .post(`${API_URL}/store/add-cuisinePhoneNumber`, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_APPROVAL_LIST,
          payload: res.data,
        });
        if (cb) cb();
        await dispatch(setMerchantLoading(false));
        toast.success("Successfully Added");
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};
export const addFinancialPhoneNumberStore = (body) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .post(`${API_URL}/store/add-financialPhoneNumber`, body)
      .then(async (res) => {
        await dispatch(setMerchantLoading(false));
        toast.success("Successfully Added");
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const dailyOpsLastUpdate = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/sale/latest/ZSDaily`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ZS_DAILY_UPDATE,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const dailyOpsLastUpdatePartner = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
      .get(`${API_URL}/sale/latest/ZSDaily`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ZS_DAILY_UPDATE,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateSoftwareFee = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/update/software-fee`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const downloadRids = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/sale/download/rid`, data, {
        responseType: "blob",
      })
      .then(async (res) => {
        fileDownload(res.data, `rid_${new Date()}.xls`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const sendRIDMail = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/sale/send/rid`, data)
      .then(async (res) => {
        await toast.success("Successfully Send Mail");
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const approvedCPCTicket = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/brand/cpc/approved`;
    employee_http
      .post(url, data, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.GET_ALL_CPC,
        //   payload: res.data,
        // });
        // await dispatch(fetchCPCRequests());
        toast.success("Approved Successfully");
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updatePartnerGroups = (id, data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/partner-buckets/updatePartnerGroup`, { id, data })
      .then(async (res) => {
        // if (res.data.status === "created") {
        //   await dispatch({
        //     type: actionTypes.UPDATE_STORE_LIST,
        //     payload: res.data,
        //   });
        // } else {
        //   await dispatch({
        //     type: actionTypes.UPDATE_STORE_APPROVAL_LIST,
        //     payload: res.data,
        //   });
        // }
        // if (cb) {
        //   cb();
        // }
        await dispatch(setEmployeeLoading(false));
        toast.success("Updated !");
      })
      .catch(() => {
        toast.error("Unable to update Store brands");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateStoreAggregaterName = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/update/swiggy-zomato-name`, data)
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_SWIGGY_ZOMATO_NAME,
        //   payload: res.data,
        // });
        await dispatch(setEmployeeLoading(false));
        toast.success("Updated !");
      })
      .catch(() => {
        toast.error("Unable to update Store");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchActiveStorelList = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/store?status=created&state=active`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_APPROVAL_LIST,
          payload: res.data,
        });
        await dispatch({
          type: actionTypes.SET_STORE_LIST,
          payload: res.data,
        });

        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchTechSupprot = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/store/all/techsupprot`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TECH_SUPPROT,
          payload: res.data,
        });

        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const actionTechSupprot = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/action/techsupprot`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TECH_SUPPROT,
          payload: res.data,
        });

        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const approvedTechSupprot = (formData) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/store/approved/techsupprot`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TECH_SUPPROT,
          payload: res.data,
        });

        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
